<template>
  <div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#E9F3FF', color: '#333' }"
      style="font-size: 16px"
      stripe
    >
      <el-table-column prop="title" label="项目" width="250">
        <template slot-scope="{ row }">
          <span>{{ row.title }}</span>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(year, index) in year"
        :key="index"
        :label="year"
        align="center"
      >
        <el-table-column align="right" prop="value" label="金额(万元)">
          <template slot-scope="{ row }">
            <span>{{ thousandBitSeparator(row.value[index]) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" label="占比">
          <template slot-scope="{ row }">
            <span>{{ row.compare[index] }}</span>
          </template>
        </el-table-column>
        <el-table-column align="right" v-if="index != 0" label="变动率">
          <template slot-scope="{ row }">
            <span>{{ row.change[index] }}</span>
          </template>
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data: {
    return: {},
  },
  components: {},
  props: {
    tableData: Array,
    year: Array,
  },
  methods: {
    thousandBitSeparator: function (cellValue) {
      let value = cellValue;

      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
};
</script>

<style>
</style>