<template>
  <div ref="ele" style="width: 100%; height: 360px"></div>
</template>
<script>
export default {
  props: {
    seriesIndex: {
      type: Number,
      default: 0,
    },
    chart: {
      type: Object,
    },
  },
  data() {
    return {
      series: [
        [
          {
            barWidth: 30,
            name: "流入",
            type: "bar",
            stack: "总量",
            itemStyle: {
              emphasis: {
                barBorderRadius: 4,
              },
              normal: {
                barBorderRadius: 4,
              },
            },
            label: {
              show: true,
              position: "insideLeft",
              color: "#333",
            },
            data: [
              {
                value: "320",
                name: "筹资活动1",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "320",
                name: "筹资活动2",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "320",
                name: "筹资活动3",
                label: {
                  formatter: "{b}",
                },
              },
            ],
          },
          {
            barWidth: 30,
            name: "流出",
            type: "bar",
            stack: "总量",
            label: {
              show: false,
              position: "left",
            },
            itemStyle: {
              emphasis: {
                barBorderRadius: 4,
              },
              normal: {
                barBorderRadius: 4,
              },
            },
            data: [-120, -132, -101],
          },
        ],
        [
          {
            barWidth: 30,
            name: "流入",
            type: "bar",
            itemStyle: {
              emphasis: {
                barBorderRadius: 4,
              },
              normal: {
                barBorderRadius: 4,
              },
            },
            label: {
              show: true,
              position: "insideLeft",
              color: "#333",
            },
            data: [
              {
                value: "320",
                name: "筹资活动1",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "320",
                name: "筹资活动2",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "320",
                name: "筹资活动3",
                label: {
                  formatter: "{b}",
                },
              },
            ],
          },
          {
            barWidth: 30,
            name: "流出",
            type: "bar",
            itemStyle: {
              emphasis: {
                barBorderRadius: 4,
              },
              normal: {
                barBorderRadius: 4,
              },
            },
            label: {
              show: true,
              position: "insideRight",
              color: "#333",
            },
            data: [
              {
                value: "-320",
                name: "筹资活动1",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "-320",
                name: "筹资活动2",
                label: {
                  formatter: "{b}",
                },
              },
              {
                value: "-320",
                name: "筹资活动3",
                label: {
                  formatter: "{b}",
                },
              },
            ],
          },
        ],
      ],
      util: 10000,
      maxArr: [],
      nameArr: [],
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    computedValue(value) {
      value = value / this.util;
      try {
        value = value.toFixed(2);
      } catch (error) {}
      return value;
    },
    setData1(data) {
      // console.log(data);
      let max = -Infinity;
      const in_out = ["income", "outcome"];
      in_out.map((name, i) => {
        let itme = this.series[this.seriesIndex][i];

        const dataS = itme.data;

        dataS.map((item, j) => {
          data[name][j].label = item.label;
          // data[name][j].value = this.computedValue(data[name][j].value);
          data[name][j].value = data[name][j].value;
          if (+data[name][j].value > +max) max = data[name][j].value;
          if (name == "outcome") {
            data[name][j].value = "-" + data[name][j].value;
          }
        });

        this.series[this.seriesIndex][i].data = data[name];
      });

      this.maxArr[this.seriesIndex] = max == "0.00" ? 1 : max;
      this.init();
    },
    setData2(data) {
      let max = -Infinity;
      const in_out = ["income_subjects", "expenses_subjects"];
      in_out.map((name, i) => {
        let itme = this.series[this.seriesIndex][i];
        const dataS = itme.data;
        dataS.map((item, j) => {
          item.value = this.computedValue(data[name].body[j].value);
          // item.value = data[name].body[j].value;
          if (+item.value > +max) max = item.value;
          item.name = data[name].body[j].name;
          if (name == "expenses_subjects") {
            item.value = "-" + item.value;
          }
        });
        dataS.reverse();

        // this.series[this.seriesIndex][i].data = data[name];
      });

      this.maxArr[this.seriesIndex] = max == "0.00" ? 1 : max;
      this.init();
    },

    init() {
      const series = this.series[this.seriesIndex];

      var option = {
        color: ["#529EE2", "#F7882B"],
        backgroundColor: "#fff",
        title: {
          show: true,
          text: "单位：万元",
          left: "5%",
          top: "5%",

          textStyle: {
            color: "#999",
            // fontStyle: "oblique",
            fontSize: 12,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
          formatter: (series) => {
            var html = `<div style="padding:5px"><div>${
              this.nameArr[this.seriesIndex] || ""
            }</div>`;
            series.map((item) => {
              html += `<div style="padding-left:5px;margin-top: 5px"><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                item.color
              }"></span>${item.seriesName}: ${this.thousandBitSeparator(
                item.value
              )}</div>`;
            });
            html += "</dtv>";
            return html;
          },
        },
        legend: {
          top: "top",
          right: "center",
        },
        grid: {
          left: "10%",
          right: "10%",
          bottom: "8%",
          containLabel: true,
        },
        xAxis: [
          {
            max: this.maxArr[this.seriesIndex],
            min: -this.maxArr[this.seriesIndex],
            type: "value",
            showMaxLable: true, //显示最大值
            showMinLable: true, //显示最小值
            axisLine: {
              show: false,
              alignWithLabel: false,
            },
            axisTick: {
              show: false,
            },

            splitLine: {
              show: true,

              lineStyle: {
                type: "dashed",
                color: "#F0F0F0",
              },
            },
          },
        ],
        yAxis: [
          {
            position: "left",
            show: true,
            type: "category",
            axisTick: {
              show: false,
            },
            data: ["", "", ""],
            axisLine: {
              show: true,
              alignWithLabel: false,

              lineStyle: {
                color: "#999",
                width: "1",
              },
            },
            axisLabel: {
              color: "#333",
            },
            axisTick: {
              show: false,
            },

            splitLine: {
              show: false,
            },
          },
        ],
        series,
      };
      const myChart = this.$echarts.init(this.$refs.ele);

      myChart.setOption(option);
      myChart.on(
        "mouseover",
        (params) => (this.nameArr[this.seriesIndex] = params.name)
      );
    },
    thousandBitSeparator(cellValue) {
      // let value = cellValue / 10000;
      let value = cellValue;
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
};
</script>
