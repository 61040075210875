<template>
  <div class="c_table">
    <div
      style="width: 100%; margin-top: 10px"
      class="el-table el-table--fit el-table--enable-row-hover el-table--enable-row-transition el-table--small"
    >
      <div class="el-table__header-wrapper table-responsive-sm">
        <table style="width: 100%; font-size: 16px">
          <thead class="has-gutter">
            <tr style="color: #333; height: 50px">
              <th
                class="is-left is-leaf"
                style="width: 200px; padding-left: 8px"
              >
                指标名称
              </th>
              <th class="is-center" style="width: 130px">当前值</th>
              <th
                class="is-center is-leaf row justify-content-around"
                style="height: 50px; align-items: center"
              >
                <span>行业优秀值</span><span>行业平均值</span
                ><span>行业较差值</span>
              </th>
              <!-- <th class="is-left is-leaf" style="width:54%;padding-left:20px">项目描述</th> -->
            </tr>
          </thead>
        </table>
      </div>
      <div class="el-table__body-wrapper is-scrolling-none">
        <table
          class="el-table__body table-striped"
          style="width: 100%; font-size: 16px"
        >
          <tbody>
            <tr
              class="el-table__row"
              v-for="(item, index) in tableData"
              :key="index"
            >
              <td
                class="is-left"
                style="cursor: pointer; width: 200px; padding-left: 8px"
              >
                <div
                  v-if="
                    item.指标名称 == '现金流动负债比(%)' ||
                    item.指标名称 == '全部资产现金回收率(%)'
                  "
                >
                  <span>{{ "年化后" + item.指标名称 }}</span>
                </div>
                <div v-else>
                  <span>{{ item.指标名称 }}</span>
                </div>
              </td>
              <td style="cursor: pointer; width: 120px" class="is-center">
                {{ item.当前值 }}
              </td>
              <td style="cursor: pointer; width: 345px">
                <div class="row justify-content-around" style="width: 100%">
                  <div>
                    {{ item.优秀值 }}
                  </div>
                  <div>
                    {{ item.平均值 }}
                  </div>
                  <div>
                    {{ item.较差值 }}
                  </div>
                </div>
                <div style="padding: 5px; margin-top: 5px">
                  <div class="slider">
                    <div class="slider1">
                      <div
                        class="sliders1"
                        :style="
                          item.area === '1'
                            ? { left: item.tag - 7 + '%' }
                            : { display: 'none' }
                        "
                      ></div>
                      <div class="radio"></div>
                    </div>
                    <div class="slider2">
                      <div
                        class="sliders2"
                        :style="
                          item.area === '2'
                            ? { left: item.tag - 7 + '%' }
                            : { display: 'none' }
                        "
                      ></div>
                      <div class="radio"></div>
                    </div>
                    <div class="slider3">
                      <div
                        class="sliders3"
                        :style="
                          item.area === '3'
                            ? { left: item.tag - 7 + '%' }
                            : { display: 'none' }
                        "
                      ></div>
                      <div class="radio"></div>
                    </div>
                    <div class="slider4">
                      <div
                        class="sliders4"
                        :style="
                          item.area === '4'
                            ? { left: item.tag - 7 + '%' }
                            : { display: 'none' }
                        "
                      ></div>
                    </div>
                  </div>
                  <!-- {{item.area}}{{item.tag}} -->
                </div>
              </td>
              <!-- <td v-html="item.描述" style="width:100%;padding-left:20px">
              </td> -->
            </tr>
            <tr v-if="!tableData.length">
              <td class="is-center" colspan="5">暂无数据</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
let diffNmae = {};
export default {
  props: {
    clickRow: Function,
    tableData: Array,
  },
  data() {
    return {
      current: 0,
    };
  },
  methods: {
    nameClick(index, row) {
      this.current = index;
      this.clickRow(row);
    },
    genNamCount(name, key) {
      let conut = 0;
      this.tableData.map((row) => {
        if (row[key] == name) conut++;
      });
      return conut;
    },

    // objectSpanMethod({ row, column, rowIndex, columnIndex }) {
    //   if (columnIndex === 0) {
    //     if (!diffNmae.hasOwnProperty(row.项目)) {
    //       diffNmae[row.项目] = rowIndex;
    //       return {
    //         rowspan: this.genNamCount(row.项目, "项目"),
    //         colspan: 1,
    //       };
    //     } else {
    //       return {
    //         colspan: 0,
    //         rowspan: 0,
    //       };
    //     }
    //   } else if (columnIndex == 5 && rowIndex == this.tableData.length - 1) {
    //     diffNmae = {};
    //   }

    //   // if (columnIndex === 0) {
    //   //   //用于设置要合并的列
    //   //   if (rowIndex % 2 === 0) {
    //   //     //用于设置合并开始的行号
    //   //     return {
    //   //       rowspan: 2, //合并的行数
    //   //       colspan: 1, //合并的列数，设为０则直接不显示
    //   //     };
    //   //   } else {
    //   //     return {
    //   //       rowspan: 0,
    //   //       colspan: 0,
    //   //     };
    //   //   }
    //   // }
    // },
  },
};
</script>
<style lang="less" scoped>
table {
  thead {
    tr {
      th {
        color: #333;
      }
    }
  }
  tbody {
    tr {
      td {
        color: #333;
      }
    }
  }
}
.c_table {
  /deep/.el-table__body tr.current-row > td {
    background-color: #fff !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  /*鼠标移入某行时的背景色*/
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #fff;
  }
}
</style>
