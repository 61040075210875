import service from "@/common/request.js";

/**
 * 以下全部传入这个格式
{
    "company_code": "32ee0dc2-4ff5-11eb-90a4-00155d0a3014",
    "year": 2019,
    "report_time": "00",
    "report_type": "00",
    "industry_code": "sw250101"
}
 */
/**
 * 现金流能力分析，返回各指标得分
 *
 */
export const capacity = (param) =>
  service.post("/risk/cash-flow/capacity/", param);
 
/**
 * 现金流能力分析，返回各指标得分
 *
 */
export const history = (param) =>
  service.post("/risk/cash-flow/capacity-five-terms-history/", param);

/**
 * 现金流能力分析，返回各指标得分
 *
 */
export const capacity_history = (param) =>
  service.post("/risk/cash-flow/capacity-history/", param);

/**
 * 现金流能力分析，返回各指标得分
 *
 */
export const capacity_summa = (param) =>
  service.post("/risk/cash-flow/capacity-summary/", param);

/**
 * 现金流能力分析，返回各指标得分
 *
 */

export const in_and_out = (param) =>
  service.post("/risk/cash-flow/in-and-out/", param);

/**
 * 现金流能力分析，返回各指标得分
 *
 */

export const top_n = (param) =>
  service.post("/risk/cash-flow/top-n/", param);

/**
 * 行业特色分析，返回各指标得分
 *
 */

 export const analysis = (param) =>
 service.post("/risk/industry-analysis/", param);

 /**
 * 结构分析，返回各指标得分
 *
 */

 export const structure = (param) =>
 service.post("/risk/cash-flow/structure/", param);
