<template>
  <div>
    <div class="legend">
      <div class="bk" style="background: #529ee2"></div>
      <div>流入</div>
      <div class="bk" style="background: #f7882b; margin-left: 10px"></div>
      <div>流出</div>
    </div>
    <div ref="Waterfall" style="width: 100%; height: 350px"></div>
  </div>
</template>

<script>
export default {
  props: {
    seriesIndex: {
      type: Number,
      default: 0,
    },
    chart: {
      type: Object,
    },
  },
  data() {
    return {
      datas: [],
      titleData: [],
    };
  },
  watch: {
    type() {
      this.initchart();
      this.setData1();
    },
  },
  mounted() {
    // this.setData1()
    // this.initchart();
  },
  methods: {
    setData1(data) {
      // console.log(data.in_and_out_come);
      this.datas = [];
      this.datas.push(data.in_and_out_come[4][0] / 10000); //期初

      this.datas.push(data.in_and_out_come[1][0] / 10000); //流入
      this.datas.push(data.in_and_out_come[2][0] / 10000); //流出

      this.datas.push(data.in_and_out_come[1][1] / 10000); //流入
      this.datas.push(data.in_and_out_come[2][1] / 10000); //流出

      this.datas.push(data.in_and_out_come[1][2] / 10000); //流入
      this.datas.push(data.in_and_out_come[2][2] / 10000); //流出

      this.datas.push(data.in_and_out_come[4][1] / 10000); //期末

      let dataList = data.in_and_out_come[3].map((item) => {
        return item / 10000;
      });

      this.initchart(dataList);
    },
    initchart(data) {
      // console.log(data);
      var option = {
        tooltip: {
          trigger: "axis",

          formatter: (series) => {
            let name = series[1].name;
            let value = "";
            if (series[1].value == 0) {
              value = 0;
            } else {
              value = this.thousandBitSeparator(series[1].value);
            }
            return name + " : " + value;
          },
        },
        // legend: {
        //   data: ["Expenses", "Income"],
        // },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          axisTick: {
            //x轴刻度线
            show: false,
          },
          axisLine: {
            show: false,
          },
          data: [
            "期初余额",
            "经营活动流入",
            "经营活动流出",
            "投资活动流入",
            "投资活动流出",
            "筹资活动流入",
            "筹资活动流出",
            "期末余额",
          ],
          axisLabel: {
            interval: 0,
            formatter: function(value) {
              var ret = ""; //拼接加\n返回的类目项
              var maxLength = 4; //每项显示文字个数
              var valLength = value.length; //X轴类目项的文字个数
              var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              if (rowN > 1) {
                //如果类目项的文字大于5,
                for (var i = 0; i < rowN; i++) {
                  var temp = ""; //每次截取的字符串
                  var start = i * maxLength; //开始截取的位置
                  var end = start + maxLength; //结束截取的位置
                  //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
                  temp = value.substring(start, end) + "\n";
                  ret += temp; //凭借最终的字符串
                }
                return ret;
              } else {
                return value;
              }
            },
          },
        },
        yAxis: {
          type: "value",
          name: "单位：万元",
        },
        series: [
          {
            name: "Placeholder",
            type: "bar",
            stack: "Total",
            itemStyle: {
              borderColor: "transparent",
              color: "transparent",
            },
            emphasis: {
              itemStyle: {
                borderColor: "transparent",
                color: "transparent",
              },
            },
            data: data,
          },
          {
            type: "bar",
            stack: "Total",
            itemStyle: {
              normal: {
                //好，这里就是重头戏了，定义一个list，然后根据所以取得不同的值，这样就实现了，不同条颜色

                color: function(params) {
                  var colorList = [
                    "#3EBB9F",
                    "#529EE2",
                    "#F7882B",
                    "#529EE2",
                    "#F7882B",
                    "#529EE2",
                    "#F7882B",
                    "#3EBB9F",
                  ];

                  return colorList[params.dataIndex];
                },
              },
            },

            data: this.datas,
          },
        ],
      };
      this.$echarts.init(this.$refs.Waterfall).setOption(option);
    },
    thousandBitSeparator(cellValue) {
      // let value = cellValue / 10000;
      let value = cellValue;
      if (value) {
        return Number(value)
          .toFixed(2)
          .replace(/(\d)(?=(\d{3})+\.)/g, ($0, $1) => {
            return $1 + ",";
          })
          .replace(/\.$/, "");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.legend {
  display: flex;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  .bk {
    width: 24px;
    height: 14px;
    margin-right: 5px;
    border-radius: 3px;
  }
}
</style>
